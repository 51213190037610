<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Teman IJL</h3>
        </div>
        <div class="panel-body p-5">
          <vue-good-table
            :search-options="{
              enabled: true,
              placeholder: 'Masukkan Nama Tim/Desa',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="isLoading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <button
                  type="button"
                  @click="showModal"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-warning btn-circle btn-icon m-r-5"
                    v-on:click="
                      editTarget(
                        props.row.id_tim,
                        props.row.nama_tim,
                        props.row.kec_tim,
                        props.row.kel_tim,
                        props.row.target_tim
                      )
                    "
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.id_tim)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Input Tim Om Oyo</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="form-group">
          <label for="nama_tim">Nama Tim</label>
          <input
            type="text"
            v-model="form.nama_tim"
            class="form-control text-uppercase"
            placeholder="Nama Tim"
            :class="{
              'is-invalid': formValidate.nama_tim,
            }"
          />
        </div>
        <div class="form-group">
          <label for="kec_tim">Kecamatan</label>
          <select
            v-model="form.kec_tim"
            v-on:change="fetchKelurahan($event)"
            class="form-control text-uppercase"
            :class="{
              'is-invalid': formValidate.kec_tim,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kecamatan in listKecamatan"
              :value="kecamatan.nama_kecamatan"
              :key="kecamatan.id_kec"
              :data-key="kecamatan.id_kec"
            >
              {{ kecamatan.nama_kecamatan }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="kel_tim">Desa</label>
          <select
            v-model="form.kel_tim"
            class="form-control text-uppercase"
            :class="{
              'is-invalid': formValidate.kel_tim,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kelurahan in listKelurahan"
              :value="kelurahan.nama_kelurahan"
              :key="kelurahan.nama_kelurahan"
            >
              {{ kelurahan.nama_kelurahan }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="target_tim">Target</label>
          <input
            v-model="form.target_tim"
            type="number"
            class="form-control"
            :class="{
              'is-invalid': formValidate.target_tim,
            }"
            placeholder="Target Tim"
          />
        </div>
      </div>
      <template #modal-footer>
        <button
          @click="postData"
          class="btn btn-block btn-info"
          variant="primary"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          Simpan
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Nama Teman IJL",
          field: "nama_tim",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "kec_tim",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Desa",
          field: "kel_tim",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Target",
          field: "target_tim",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      form: {
        id_tim: "",
        nama_tim: "",
        kec_tim: "",
        kel_tim: "",
        target_tim: "",
      },
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      listKecamatan: [],
      listKelurahan: [],
      link: "/v1/tim/create/",
    };
  },
  mounted() {
    this.fetchData();
    this.fetchKecamatan();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/tim/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.isLoading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      if (this.form.id_tim) {
        this.link = "/v1/tim/update/";
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.isLoading = false;
            this.formValidate = response.data.message;
          } else {
            this.isLoading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.modalVisible = false;
            this.conditionMet = true;
            setTimeout(() => {
              Swal.close();
              this.resetForm();
              this.fetchData();
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/tim/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    editTarget(id_tim, nama_tim, kec_tim, kel_tim, target_tim) {
      this.form.id_tim = id_tim;
      this.form.nama_tim = nama_tim;
      this.form.kec_tim = kec_tim;
      this.form.kel_tim = kel_tim;
      this.form.target_tim = target_tim;
      this.modalVisible = true;
      this.conditionMet = false;
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },

    resetForm() {
      this.form.id_tim = "";
      this.form.nama_tim = "";
      this.form.kec_tim = "";
      this.form.kel_tim = "";
      this.form.target_tim = "";
      this.formValidate = [];
    },

    fetchKecamatan() {
      axios
        .get("/v1/daerah/kecamatan/7209", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKelurahan(event) {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const key = selectedOption.getAttribute("data-key");

      console.log(key);
      axios
        .get("/v1/daerah/kelurahan/" + key, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>